/**
 * Created by Casper on 29/07/15.
 */
    function berekenBMI(gewicht, lengte) {
    var gewicht = gewicht;
    var lengte = lengte;
    var meters = lengte / 100;
    var BMI = gewicht / meters / meters;
    return afronden(BMI,1);
    }

    function afronden(x,y) {
    return (Math.round(x*Math.pow(10,y)))/Math.pow(10,y);
    }